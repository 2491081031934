$(document).ready(function () {

    /*
    Convertion du module date en Français
     */
    $('input[type="date"]').on('focusin', function () {

        var i18n = {
            months: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
            weekdays: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
        };

        var datepicker = UIkit.datepicker(this, {i18n: i18n});
    });

    //Demande de DAP
    $('input[name="radiosubject"]').change(function (){
        if($(this).val() == "ppc"){
            $('.dap-complement').show();
        }
        else{
            $('.dap-complement').hide();
        }
    });

    //Edit DAP
    $('.editDAP').hide();
    $('.openEditDAP').click(function (e) {
        e.stopPropagation();
        $('.editDAP').show();
    });
    $('.closeEditDAP').click(function () {
        $('.editDAP').hide();

    });

    $('input[name="demande_ppc"]').change(function (){
        if($(this).val() == "initial"){
            $('.dap_initial').show();
            $('.dap_renouvellement').hide();
        }
        else{
            $('.dap_initial').hide();
            $('.dap_renouvellement').show();
        }
    });


    $('.inputfile').change(function (e){
        var label	 = $(this)[0].nextElementSibling,
            labelVal = label.innerHTML,
            fileName = '';

        fileName = e.target.value.split( '\\' ).pop();

        if( fileName )
            label.querySelector( 'span' ).innerHTML = fileName;
        else
            label.innerHTML = labelVal;

    });

    $('#prevbutt').click(function(){
        $('#DAP_pdf').submit();
    })

    $('.close').click(function(){
        window.location.href = $('#currenturl').val();
    });


    /* Edition Sign or Edit DAP */
    $('#btnSignDAP').click(function (){
        $(this).attr("disabled", true);
        document.getElementById("loader").style.display = "block";
        $('.uk-modal-body').hide();
        $('#submitSignDAP').click();
    });

    $('#btnEditDAP').click(function (){
        $(this).attr("disabled", true);
        $('#submitEditDAP').click();
    });


    /* Champs date simple */
    $('.datepicker-custom').daterangepicker({
        showDropdowns: true,
        autoUpdateInput: false,
        minDate: '01/01/1930',
        maxDate: '01/01/2050',
        locale: {
            format: 'DD/MM/YYYY',
            cancelLabel: "Clear",
            customRangeLabel: "Plage de dates",
            daysOfWeek: [
                "Dim",
                "Lun",
                "Mar",
                "Mer",
                "Jeu",
                "Ven",
                "Sam"
            ],
            monthNames: [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre"
            ],
            firstDay: 1
        },
        linkedCalendars: false,
        showBeforeDate: true,
        showAfterDate: true,
        showSpecificDate: true
    });

    $('.datepicker-custom').on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD/MM/YYYY') + ' - ' + picker.endDate.format('DD/MM/YYYY'));

        var href = $('#rangedatelink').attr('href');
        href += '&date-deb=' + picker.startDate.format('YYYY-MM-DD')
        href += '&date-fin=' + picker.endDate.format('YYYY-MM-DD')
        $('#rangedatelink').attr('href', href);

        window.location.href = $('#rangedatelink').attr('href');
    });

    $('.datepicker-custom').on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });

    $('#rangedatespan').click(function (e){
        e.stopPropagation();
        e.preventDefault();
        $('.datepicker-custom').click();
    });

    $('.datepicker-custom').click();
    $('.cancelBtn').click();


    $('.getData').on('click', function(){
        var name = $(this).attr('data-name');
        var chrono = $(this).attr('data-chrono');
        var seq = $(this).attr('data-seq');

        getData($(this), name, chrono, seq);
    });

    $('.getData').click()
});


function getData(el, data_name, dossier, intervention){
    $.ajax({
        url: '/patient/getdata',
        type: 'GET',
        dataType: 'json',
        data: {
            data_name: data_name,
            dossier: dossier,
            intervention: intervention
        },
        success: function(response) {
            if (response) {
                let html = '';

                if(data_name == 'p9095' ||data_name == 'vt'){
                    el.css('color', '#494b4d');
                    html = 'P9095<br/><b style="color: #494b4d">' + response + '</b>'
                }
                else if(data_name == 'obs'){
                    if(response >= 3)
                        el.css('background-color', '#8cbd36');
                    else
                        el.css('background-color', '#e15656');

                    html = 'OBS<br/><b style="color: white">' + response + '</b>'
                }
                else if(data_name == 'iahResiduel'){
                    if(response <= 10)
                        el.css('background-color', '#8cbd36');
                    else
                        el.css('background-color', '#e15656');

                    html = 'IAHR<br/><b style="color: white">' + response + '</b>'
                }
                else if(data_name == 'fuites'){
                    el.css('background-color', '#8cbd36');
                    el.css('width', '125px');

                    if(response == 19)
                        response = 'BONNES';
                    else if(response == 20)
                        response = 'ACCEPTABLES';
                    else
                        response = 'ANORMALES';

                    html = 'FUITES<br/><b style="color: white">' + response + '</b>'
                }
                else if(data_name == 'deambulation'){
                    el.css('background-color', '#8cbd36');

                    if(response == 1)
                        response = 'OUI';
                    else
                        response = 'NON';

                    html = 'Déamb<br/><b style="color: white">' + response + '</b>'
                }
                else if(data_name == 'duree'){
                    el.css('background-color', '#8cbd36');
                    html = 'Durée<br/><b style="color: white">' + response + '</b>'
                }

                el.append(html);
            }
        },
        xhrFields: {
            withCredentials: true
        },
        crossDomain: true
    });
}
