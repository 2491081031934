"use strict";

$(document).ready(function () {
    $('#new-on-modal').formValidation({
        framework: "uikit",
        trigger: 'blur',
        button: {
            selector: '#btnSubmit-DAP',
            disabled: 'disabled'
        },
        fields: {
            nom: {
                validators: {
                    notEmpty: {
                        message: "Le nom du patient est requis"
                    }
                }
            },
            date_naissance: {
                validators: {
                    notEmpty: {
                        message: "La date de naissance est requise  "
                    }
                }
            },
            tel: {
                validators: {
                    notEmpty: {
                        message: "Le numéro de téléphone est requis"
                    }
                }
            },
            codepostal: {
                validators: {
                    zipCode: {
                        country:'FR',
                        message: "Le code postal n'est pas valide"
                    }
                }
            },
            immat:{
                validators: {
                    regexp: {
                        message: "Le format du numéro de sécurité sociale n'est pas valide.",
                        regexp: /[1-2][0-9]{2}(0[1-9]|1[0-2]|20|3[0-9]|4[0-2]|[5-9][0-9])(0[1-9]|[1-9][0-9]|2A|2B)([0]{2}[1-9]|0[1-9][0-9]|[1-9][0-9]{2})([0]{2}[1-9]|0[1-9][0-9]|[1-9][0-9]{2})|([1-2][9]{12})/i
                    },
                }
            },
            fileNewPatient: {
                validators: {
                    file: {
                        maxSize: 2000000,
                        message: 'Fichier sélectionné trop volumineux, 2Mo max.'
                    }
                }
            },
            consent:{
                validators: {
                    notEmpty: {
                        message: "La confirmation est requise"
                    }
                }
            }
        }
    });


    $('#frmEditDap').formValidation({
        framework: "uikit",
        trigger: 'blur',
        button: {
            selector: '#submitSignDAP',
            disabled: 'disabled'
        },
        fields: {
            amelioration_iah: {
                validators: {
                    notEmpty: {
                        message: "Le champ est requis."
                    }
                }
            },
            filePatient: {
                validators: {
                    file: {
                        maxSize: 2000000,
                        message: 'Fichier sélectionné trop volumineux, 2Mo max.'
                    }
                }
            },
            consent:{
                validators: {
                    notEmpty: {
                        message: "La confirmation est requise"
                    }
                }
            }
        }
    });


});
