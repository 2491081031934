"use strict";

$(document).ready(function() {
    $('#frmcontactNew').formValidation({
        framework: "uikit",
        trigger: 'blur',
        row: {
            selector: 'td'
        },
        fields: {
            radiosubject: {
                validators: {
                    notEmpty: {
                        message: "Le type d'appareillage est requis"
                    }
                }
            },
            nom: {
                validators: {
                    notEmpty: {
                        message: "Le nom du patient est requis"
                    }
                }
            },
            tel: {
                validators: {
                    notEmpty: {
                        message: "Le téléphone du patient est requis"
                    }
                }
            }
        }
    });
    $('#frmcontact').formValidation({
        framework: "uikit",
        trigger: 'blur',
        row: {
            selector: 'td'
        },
        fields: {
            radio_subject: {
                validators: {
                    notEmpty: {
                        message: "Le type de demande est requis"
                    }
                }
            },
            patient_name: {
                validators: {
                    notEmpty: {
                        message: "Le nom du patient est requis"
                    }
                }
            },
            message: {
                validators: {
                    notEmpty: {
                        message: "Le champ message est obligatoire"
                    }
                }
            }
        }
    });
});
