"use strict";

$(document).ready(function() {
    $('#frmEditUser').formValidation({
        framework: "uikit",
        trigger: 'blur',
        row: {
            selector: 'td'
        },
        fields: {
            nom: {
                validators: {
                    notEmpty: {
                        message: "Le nom de l'utilisateur est requis"
                    }
                }
            },
            prenom: {
                validators: {
                    notEmpty: {
                        message: "Le prénom de l'utilisateur est requis"
                    }
                }
            },
            login: {
                validators: {
                    notEmpty: {
                        message: "L'identifiant est requis"
                    }
                }
            },
            autorisation: {
                validators: {
                    notEmpty: {
                        message: "L'autorisation est requise"
                    }
                }
            },
            password: {
                validators: {

                    identical: {
                        field: "cpassword",
                        message: "Les mots de passes saisis sont différents"
                    }
                }
            },
            confirmpw: {
                validators: {
                    identical: {
                        field: "password",
                        message: "Les mots de passes saisis sont différents"
                    }
                }
            }
        }
    });
});