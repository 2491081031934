"use strict";

$(document).ready(function () {
    $('#new-on-modal').formValidation({
        framework: "uikit",
        trigger: 'blur',
        fields: {
            nom: {
                validators: {
                    notEmpty: {
                        message: "Le nom de l'utilisateur est requis"
                    }
                }
            },
            date_naissance: {
                validators: {
                    notEmpty: {
                        message: "La date de naissance est requise  "
                    }
                }
            },
            tel: {
                validators: {
                    notEmpty: {
                        message: "Le numéro de téléphone est requis"
                    }
                }
            }
        }
    });
});