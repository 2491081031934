"use strict";

$(document).ready(function() {
    $('#frmEditDap').formValidation({
        framework: "uikit",
        trigger: 'blur',
        row: {
            selector: 'td'
        },
        fields: {
            consent: {
                validators: {
                    notEmpty: {
                        message: "Consentement requis"
                    }
                }
            }
        }
    });

});
